export const TextStyleDefaults = [
	{name: 'Bodycopy', shortcut: null, tag: 'bodycopy'},
	{name: 'Heading 1', shortcut: null, tag: 'h1'},
	{name: 'Heading 2', shortcut: null, tag: 'h2'},
	// {name: 'Heading 1', shortcut: 'cmd+shift+1', hotKeyConfig: { keyCode: 49, metaKey: true, shiftKey: true, }, tag: 'h1'},
	// {name: 'Heading 2', shortcut: 'cmd+shift+2', hotKeyConfig: { keyCode: 50, metaKey: true, shiftKey: true, }, tag: 'h2'},
	{name: 'Caption', tag: '.caption', className: 'caption' },
	// {name: 'Heading 3', shortcut: 'cmd+shift+3', hotKeyConfig: { keyCode: 51, metaKey: true, shiftKey: true, }, tag: 'h3'},
];

export default {TextStyleDefaults};